.drawLogo {
	stroke-dasharray: 1;
	stroke-dashoffset: 1;
	-webkit-animation: dash 2s linear;
	animation: dash 2s linear;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
	transition: ease;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
}

@-webkit-keyframes dash {
	from {
		stroke-dashoffset: 1;
	}
	to {
		stroke-dashoffset: 0;
	}
}

@keyframes dash {
	from {
		stroke-dashoffset: 1;
	}
	to {
		stroke-dashoffset: 0;
	}
}
.logo {
	-webkit-animation: fade 2s ease;
	animation: fade 2s ease;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
}

@-webkit-keyframes fade {
	from {
		opacity: 0.9;
	}
	to {
		opacity: 0.3;
	}
}

@keyframes fade {
	from {
		opacity: 0.9;
	}
	to {
		opacity: 0.3;
	}
}
