body {
	font-family: Optima, Segoe, Segoe UI, Candara, Calibri, Arial, sans-serif;
	color: white;
}

.flex-row {
	display: flex;
	align-items: center;
	justify-content: center;
}
.flex-col {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.full-page {
	width: 100vw;
	height: 100vh;
}
.App {
	text-align: center;
}

.App-bg {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: radial-gradient(
		52.57% 77.56% at 47.43% 39.5%,
		#b10136 6.36%,
		#6c0223 41.84%
	);
	z-index: -1;
}
.bg-text {
	position: absolute;
	top: 3%;
	left: 50%;
	transform: translateX(-50%);
	font-size: 25vh;
	line-height: 90%;
	font-weight: 600;
	opacity: 0.03;
}
.main-nav {
	position: fixed;
	padding-top: 2.5%;
	width: 100vw;
	height: 2rem;
	z-index: 100;
}
.nav-inner-container {
	justify-content: space-evenly;
	width: 80%;
	height: 20px;
}
.nav-link {
	padding: 5px;
	cursor: pointer;
	background-image: linear-gradient(white, white);
	background-size: 0% 1px;
	background-repeat: no-repeat;
	background-position: left bottom;
	transition: 0.3s linear;
}

.nav-link:hover {
	background-size: 100% 1px;
}
.scroll-more-svg {
	position: fixed;
	bottom: 0;
	left: 2.5%;
	width: 20%;
	height: 100vh;
	font-size: 6vw;
	z-index: 5;
}
.section {
	position: relative;
	z-index: 1;
	background: transparent;
}
.main-text-container {
	overflow: hidden;
	flex-wrap: wrap;
	justify-content: space-evenly;
	width: 90vw;
	font-weight: 550;
	font-size: 100px;
	position: absolute;
	bottom: 20%;
	letter-spacing: 0.5vw;
	z-index: 3;
}
.S1-img {
	position: absolute;
	top: -5%;
	width: 70%;
	min-width: 500px;
	z-index: 2;
}

.S1-main-text-container {
	right: 10%;
	width: 50vw;
	font-size: 3vw;
	bottom: 30%;
	z-index: 3;
}
.S1-accent-text-container {
	position: absolute;
	right: 10%;
	width: 47.5vw;
	bottom: 10%;
	justify-content: space-between;
	z-index: 3;
}
.S2 {
	height: 80vh;
}
.S2-img {
	position: absolute;
	left: 0%;
	bottom: -10%;
	width: 53%;
}
.S2-main-text-container {
	font-size: 3vw;
	width: 60vw;
	height: 20vh;
	top: 15%;
}
.S2-details-text-container {
	position: absolute;
	top: 35%;
	right: 5%;
	width: 40%;
	z-index: 3;
}
.S2-details-text-wrapper {
	overflow: hidden;
	margin: 10px;
}
.S2-details-text {
	font-size: 14px;
}
.quad-black {
	background-color: black;
	z-index: 3;
}
.quad-01 {
	background-image: url(./assets/images/ad008890-8628-425b-b9ca-29fdf64a7dd2\ 4.png);
	background-size: cover;
}
.quad-02 {
	background-image: url(./assets/images/ad008890-8628-425b-b9ca-29fdf64a7dd2\ 3.png);
	background-size: cover;
}
.S3-main-text-container {
	font-size: 3vw;
	width: 20vw;
	height: 200px;
	top: 5%;
	left: 5%;
	z-index: 5;
}
.S3-main-text {
	margin: 0 10px;
}
.S3-details-text {
	position: absolute;
	font-size: 14px;
	letter-spacing: 1px;
	top: 38%;
	right: 18%;
	width: 30%;
	text-align: justify;
}
.S4-main-text-container {
	font-size: 3vw;
	height: 100px;
	top: 10%;
	left: 5%;
	width: 20vw;
}
.S4-carousel-container {
	justify-content: space-between;
	width: 90vw;
	height: 80vh;
	position: relative;
}
.S4-nav-arrow {
	width: 60px;
	cursor: pointer;
	z-index: 5;
}
.S4-imgs-container {
	position: absolute;
	top: 15%;
	height: 425px;
	transition: 1s ease;
}
.S4-img {
	position: absolute;
	-o-object-fit: cover;
	object-fit: cover;
	border-radius: 5px;
	border: 1px solid white;
	transition: 1s ease;
}

.S5-main-text-container {
	position: relative;
	overflow: hidden;
	font-weight: bolder;
	font-size: 4vw;
	margin: 10px;
}

.S5-spec-details-container {
	justify-content: space-evenly;
	position: relative;
	width: 90vw;
	height: 40vh;
	justify-self: flex-start;
}

.S5-spec-details-text-wrapper {
	width: 100%;
	height: 2rem;
	overflow: hidden;
	justify-content: space-between;
	padding: 5px;
}
.S5-spec-details-wrapper {
	width: 30%;
	height: 100%;
	z-index: 1;
	justify-content: flex-start;
}
.S6-main-text-container {
	position: relative;
	overflow: hidden;
	font-weight: 600;
	font-size: 3vw;
	margin-top: 10%;
}
.S6-form {
	width: 60vw;
	height: 65vh;
	align-items: flex-start;
	justify-content: space-evenly;
}
.S6-input {
	width: 100%;
	background: black;
	border: 1px solid white;
	border-radius: 5px;
	height: 2rem;
	color: white;
	font-family: Optima, Segoe, Segoe UI, Candara, Calibri, Arial, sans-serif;
	outline: none;
}
.S6-text-area {
	max-width: 100%;
	width: 100%;
	max-height: 7rem;
	height: 7rem;
}
.S6-button {
	border: 1px solid white;
	border-radius: 5px;
	width: 100px;
	height: 2rem;
	color: white;
	background: black;
	font-family: Optima, Segoe, Segoe UI, Candara, Calibri, Arial, sans-serif;
	align-self: flex-end;
	cursor: pointer;
}

.S6-button:hover {
	background: #a30031;
}
.logo {
	align-self: center;
	width: 203px;
	height: 116px;
	-o-object-fit: cover;
	object-fit: cover;
}
.logoAnim {
	-webkit-animation: fade 2s ease;
	animation: fade 2s ease;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
}

@-webkit-keyframes fade {
	from {
		opacity: 0.9;
	}
	to {
		opacity: 0.3;
	}
}

@keyframes fade {
	from {
		opacity: 0.9;
	}
	to {
		opacity: 0.3;
	}
}

@media (max-width: 1100px) {
	.S1-img {
		top: 20%;
	}
	.S2-img {
		top: 15%;
		left: 25%;
	}
	.S1-accent-text-container {
		width: 80%;
	}
	.S2-details-text-container {
		width: 90%;
		bottom: 0;
	}
	.S2-main-text-container {
		top: 10%;
	}
	.S3-main-text-container {
		justify-content: center;
		width: 100%;
		top: 5%;
		right: 0;
	}
	.S3-text {
		font-size: 12px;
	}
	.S4-imgs-container {
		top: 30%;
	}
	.S5 {
		justify-content: flex-start;
		height: 200vh;
	}
	.S5-main-text-container {
		z-index: 300;
		margin: 30%;
	}
	.S5-spec-details-container {
		flex-direction: column;
	}

	.S5-spec-details-wrapper {
		width: 100%;
	}
	.S6-form {
		width: 80vw;
	}
	.scroll-more-svg {
		display: none;
	}
}
